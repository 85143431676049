<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Sensores- Tanques y Bebederos Asignados</h4>
            <div class="small text-muted">Adminsitración por cliente de todos los tanques y bebederos con sus sensores</div>
          </b-col>
          <b-col sm="5">

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>

          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"
                    v-if="table.items.length">

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template>

                <template v-slot:row-details>
                  <b-card no-body>
                    <b-tabs card>
                      <b-tab title="Tanques" active>
                        <b-card-text>
                          <b-row>
                            <b-col>
                              <b-table class="mb-0"
                                  responsive
                                  head-variant="light"
                                  :hover="true"
                                  :small="true"
                                  :fixed="false"
                                  stacked="sm"
                                  :items="tableTanks.items"
                                  :fields="tableTanks.fields"
                                  v-if="tableTanks.items.length">                                

                                <template v-slot:cell(tank)="row">
                                  <b-media tag="li">
                                    <template v-slot:aside>
                                      <span v-if="row.item.info.image" >
                                        <b-img :src="row.item.info.image" thumbnail fluid width="100"/>
                                      </span>
                                      <span v-else>
                                        <b-img :src="row.item.image" v-if="row.item.image" thumbnail fluid width="100"/>
                                        <div class="list-sensores-thumbnail-custom" v-else>
                                          <b-icon icon="image" class="h1" v-b-tooltip.hover title="Imagen No Disponible" />
                                        </div>    
                                      </span> 
                                    </template>                                    
                                    <h5 class="mt-0 mb-1">{{row.item.code}} - {{row.item.name}}</h5>                                    
                                    <p class="mb-0">
                                      <b>Diametro:</b> {{row.item.diameter}} cm<br>
                                      <b>Alto:</b> {{row.item.height}} cm<br>                                    
                                      <b>Capacidad:</b> {{row.item.capacity}} lt<br> 
                                      <b-badge variant="success" v-if="row.item.own">Tanque Propio</b-badge>
                                      <b-badge variant="danger" v-else>Tanque de Tercero</b-badge>                                         
                                    </p>
                                  </b-media>                                  
                                </template>                                

                                <template v-slot:cell(info)="row">                                  
                                  <b-media tag="li" v-if="row.item.info">
                                    <h5 class="mt-0 mb-1" v-if="row.item.info.alias">
                                      {{row.item.info.alias}}
                                    </h5>                                    
                                    <p class="mb-0">                                      
                                      <b>Serie: </b> {{row.item.info.serial_number}}<br>
                                      <b>Vencimiento: </b> {{moment(row.item.info.expiration).format('DD/MM/YYYY')}}<br>                                      
                                      <b>Contenido: </b> {{row.item.info.type}}<br>                                                                                                          
                                    </p>
                                  </b-media>                                  
                                </template> 
                                
                                <template v-slot:cell(sensor)="row">
                                  <b-media tag="li" class="mb-0" v-if="row.item.info">                                    
                                    <b>Nodo:</b> {{row.item.info.sensor_code}} |
                                    <b>Sensor:</b> {{row.item.info.sensor_val}}<br>
                                    
                                    <div v-if="row.item.info.alert_min">
                                      <b>Alerta:</b> {{row.item.info.alert_min}}%<br>
                                    </div>
                                    <div v-if="row.item.info.alert_min_last">
                                      <b>Última Alerta:</b> {{row.item.info.alert_min_last}}%<br>
                                    </div>

                                    <b-button variant="outline-success" size="sm" class="mt-2" @click="irViewData(row.item)">
                                      <b-icon icon="graph-up" aria-hidden="true"></b-icon>  
                                        Visualizar Datos
                                      <b-icon icon="chevron-right" aria-hidden="true"></b-icon>                                                                                    
                                    </b-button>
                                  </b-media>                                                            
                                </template> 

                                <template v-slot:cell(f_action)="row">
                                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                    
                                    <b-dropdown-item @click="irViewData(row.item)">
                                      <b-icon icon="graph-up" aria-hidden="true" style="color:green"></b-icon>  
                                       Visualizar Datos
                                    </b-dropdown-item> 
                                                                       
                                    <b-dropdown-item @click="irNotifications(row.item)">
                                      <b-icon icon="bell" aria-hidden="true" style="color:green"></b-icon>  
                                       Notificaciones
                                    </b-dropdown-item> 

                                    <b-dropdown-header>CRUD</b-dropdown-header>
                                    
                                    <b-dropdown-item @click="openEditTank(row.item)">
                                      <b-icon icon="pencil" aria-hidden="true" style="color:blue"></b-icon>  
                                       Editar
                                    </b-dropdown-item>

                                    <b-dropdown-item @click="openRemoveTank(row.item)">
                                      <b-icon icon="trash" aria-hidden="true" style="color:red"></b-icon> 
                                       Eliminar
                                    </b-dropdown-item>                                                                      

                                  </b-dropdown>
                                </template>

                              </b-table>
                              <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                            </b-col>
                          </b-row>
                        </b-card-text>
                      </b-tab>

                      <b-tab title="Bebederos">
                        <b-card-text>
                          <b-row>
                            <b-col>
                              <b-table class="mb-0"
                                  responsive
                                  head-variant="light"
                                  :hover="true"
                                  :small="true"
                                  :fixed="false"
                                  stacked="sm"
                                  :items="tableTrough.items"
                                  :fields="tableTrough.fields"
                                  v-if="tableTrough.items.length">                                

                                <template v-slot:cell(alias)="row">   
                                  <b-media tag="li">
                                    <template v-slot:aside>
                                      <span v-if="row.item.info.image" >
                                        <b-img :src="row.item.info.image" thumbnail fluid width="100"/>
                                      </span>
                                    </template>                                                                                                   
                                    <h5 class="mt-0 mb-1">{{row.item.info.alias_trough}}</h5>                                                                      
                                  </b-media>
                                </template>                                
                                                             
                                <template v-slot:cell(sensor)="row">
                                  <b>Nodo:</b> {{row.item.info.sensor_code}}                                  
                                </template> 

                                <template v-slot:cell(status)="row">   
                                  <div v-if="row.item.info.last_data_register">                               
                                    <div v-if="row.item.info.last_data_register.entrada" class="list-sensors-customers-trough-open">
                                      Abierto
                                    </div>
                                    <div v-else class="list-sensors-customers-trough-close">
                                      Cerrado
                                    </div>                                  
                                  </div>
                                  <div v-else>
                                    <i>No Implementado</i>
                                  </div>
                                </template>

                                <template v-slot:cell(f_action)="row">
                                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                                                                                           
                                    <b-dropdown-item @click="irViewData(row.item)">
                                      <b-icon icon="graph-up" aria-hidden="true" style="color:green"></b-icon>  
                                       Visualizar Datos
                                    </b-dropdown-item> 
                                                                       
                                    <b-dropdown-item @click="irNotifications(row.item)">
                                      <b-icon icon="bell" aria-hidden="true" style="color:green"></b-icon>  
                                       Notificaciones
                                    </b-dropdown-item> 

                                    <b-dropdown-header>CRUD</b-dropdown-header>
                                    
                                    <b-dropdown-item @click="openEditTank(row.item)">
                                      <b-icon icon="pencil" aria-hidden="true" style="color:blue"></b-icon>  
                                       Editar
                                    </b-dropdown-item>

                                    <b-dropdown-item @click="openRemoveTank(row.item)">
                                      <b-icon icon="trash" aria-hidden="true" style="color:red"></b-icon> 
                                       Eliminar
                                    </b-dropdown-item>                                                                      

                                  </b-dropdown>
                                </template>

                              </b-table>
                              <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                            </b-col>
                          </b-row>
                        </b-card-text>
                      </b-tab>                      
                    </b-tabs>
                  </b-card>
                </template>

                <template v-slot:cell(f_tanks)="data">
                  <div class="text-success text-module-staff-custom" v-if="data.item.tanks.length">{{ data.item.tanks.length }} sensor/es asignados</div>
                  <div class="text-danger text-module-staff-custom" v-else>No se encontraron sensores asignados</div>                
                </template>

                <template v-slot:cell(id)="data">                  
                  <b>{{ data.item.id }}</b>                  
                </template>
              
                <template v-slot:cell(customers)="data">                  
                  <b-avatar :src="data.item.image"
                            v-if="data.item.image">
                  </b-avatar>
                  <b-avatar v-else 
                            icon="building"                                            
                            variant="dark">
                  </b-avatar>     
                  &nbsp;<b>{{data.item.name}}</b>                  
                  <b-icon icon="circle-fill" v-if="!data.item.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                           
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                    <b-dropdown-item @click="openAssociate()">
                       <b-icon icon="plus"></b-icon> Agregar Tanque
                    </b-dropdown-item> 

                    <b-dropdown-item @click="openDetail(data.index)">
                       <b-icon icon="chevron-compact-down"></b-icon> Listar Tanques
                    </b-dropdown-item>                    
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row class="mt-2">
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>
          </b-col>
          <b-col>
            <nav>
              <b-pagination class="pull-right  mb-0"
                            size="sm"
                            pills
                            :total-rows="getRowCount(table.items)"
                            :per-page="table.perPage"
                            v-model="table.currentPage" />
            </nav>
          </b-col>
        </b-row>
      </b-card>
      
      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->
      
      <!-- CRUD TANK -->      
      <b-modal v-model="modal.formTank.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formTank.title}}
        </div>

        <b-row v-if="crud.formTank.type_asignation=='tanque'">                 
          <b-col md="12" lg="6">
            <b-form-group label="Alias" description="Ingrese un alias para el tanque">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTank.alias">
              </b-form-input>
            </b-form-group>
          </b-col>       
          <b-col md="12" lg="6">
            <b-form-group label="Líquido de Abastecido" description="Ingrese el líquido de abastecido para el tanque">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTank.type">
              </b-form-input>
            </b-form-group>
          </b-col> 
          <b-col md="12" lg="6">
            <b-form-group label="Número de Serie" description="Ingrese el número de serie del tanque">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTank.serial_number">
              </b-form-input>
            </b-form-group>
          </b-col>       
          <b-col md="12" lg="6">
            <b-form-group label="Fecha de Vencimiento" description="Ingrese la fecha de vencimiento para este tanque">
              <b-form-datepicker size="sm" v-model="crud.formTank.expiration" placeholder="Fecha de Vencimiento" local="es"></b-form-datepicker>                      
            </b-form-group>
          </b-col>  

          <b-col md="12">
            <b-card
              header="Datos del Sensor"
              border-variant="dark"              
              header-bg-variant="secondary"
              header-text-variant="black">              

              <b-card-text>
                <b-row>
                  <b-col md="12" lg="6">
                    <b-form-group label="Nodo" description="Ingresar el Identificador del Nodo">
                      <b-form-input type="number"
                                    size="sm"
                                    v-model="crud.formTank.sensor_code">
                      </b-form-input>
                    </b-form-group>                                      
                  </b-col>
                  <b-col md="12" lg="6">
                    <b-card-text>
                        <b-form-group label="Sensor" description="Ingresar sensor asignado al tanque">
                          <b-form-select v-model="crud.formTank.sensor_val" :options="arr.select.sensors" size="sm"></b-form-select>                    
                        </b-form-group>
                    </b-card-text>
                  </b-col>                  
                </b-row>
                <b-row>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Alerta" description="Porcentaje mínimo para disparar alertas">
                        <b-form-input type="number"                                    
                                      size="sm"
                                      v-model="crud.formTank.alert_min">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Última Alerta" description="Último porcentaje mínimo para disparar alertas">
                        <b-form-input type="number"                                    
                                      size="sm"
                                      v-model="crud.formTank.alert_min_last">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>                   
                </b-row>

                <b-row>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Batería Volt" description="Volt de la batería">
                        <b-form-input type="number"                                    
                                      size="sm"
                                      disabled
                                      v-model="crud.formTank.batery_volt">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Batería Alerta" description="Volt mínimo para alertas">
                        <b-form-input type="number"                                    
                                      size="sm"
                                      disabled
                                      v-model="crud.formTank.batery_alert">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>                
                </b-row> 

                <b-row>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Latitud">
                        <b-form-input type="text"                                    
                                      size="sm"
                                      v-model="crud.formTank.lat">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>
                  <b-col lg="6">                
                    <b-card-text>
                      <b-form-group label="Longitud">
                        <b-form-input type="text"                                    
                                      size="sm"
                                      v-model="crud.formTank.lng">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>              
                  </b-col>                   
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-card-text>
                      <b-form-group label="Capacidad Operativa (litros)" 
                                    description="Capacidad máxima del tanque en litros">
                        <b-form-input type="number"
                                      step="0.01"                                    
                                      size="sm"
                                      v-model="crud.formTank.capacity">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>                             
                  </b-col>    
                  <b-col lg="6">
                    <b-card-text>
                      <b-form-group label="Distantcía del sensor (cm)" 
                                    description="Centimetros desde el alto máximo del tanque hasta donde termina el sensor">
                        <b-form-input type="number"                                    
                                      step="0.01"
                                      size="sm"
                                      v-model="crud.formTank.distance_sensors">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>                             
                  </b-col>                                    
                </b-row>

                <b-row>
                  <b-col lg="12">
                    <b-card-text>
                      <b-form-group label="Variación Exclusión (%)" 
                                    description="Porcentaje de variación para excluir registros de falsas lecturas">
                        <b-form-input type="number"                                    
                                      size="sm"
                                      v-model="crud.formTank.variation">
                        </b-form-input>
                      </b-form-group>                
                    </b-card-text>                             
                  </b-col>                  
                </b-row>
              </b-card-text>
            </b-card>                
          </b-col>
        </b-row>

        <b-row v-if="crud.formTank.type_asignation=='bebedero'">                 
          <b-col md="12" lg="6">
            <b-form-group label="Alias" description="Ingrese un alias para el bebedero">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formTank.alias_trough">
              </b-form-input>
            </b-form-group>
          </b-col>       
          <b-col md="12" lg="6">
            <b-form-group label="Nodo" description="Ingresar el Identificador del Nodo">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.formTank.sensor_code">
              </b-form-input>
            </b-form-group>                                      
          </b-col>
          <b-col lg="6">                
            <b-card-text>
              <b-form-group label="Latitud">
                <b-form-input type="text"                                    
                              size="sm"
                              v-model="crud.formTank.lat">
                </b-form-input>
              </b-form-group>                
            </b-card-text>              
          </b-col>
          <b-col lg="6">                
            <b-card-text>
              <b-form-group label="Longitud">
                <b-form-input type="text"                                    
                              size="sm"
                              v-model="crud.formTank.lng">
                </b-form-input>
              </b-form-group>                
            </b-card-text>              
          </b-col>                   
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formTank.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveTank()">Guardar</b-button>
        </div>
      </b-modal>            
    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  
  export default {  
    data: () => {
      return {
        primaryColor: '',
        access: {
          module_id: Modules.SENSORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'listSensorsTankCustomer',
          elements: {}
        },        
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, width:'50px', class:'align-middle'},
            {key: 'customers', label:'Cliente', width:'250px', class:'align-middle'},
            {key: 'f_tanks', label: 'Sensores', class:'text-center align-middle', width:'150px'},            
            {key: 'f_action', label:'', width:'100px', class:'align-middle'},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
          rowSelected: null,
        },      
        tableTanks : {
          items: [],
          fields: [            
            {key: 'tank', label: 'Información del Tanque', class:'align-middle'},
            {key: 'info', label:'Información Complementaria', class:'align-middle'},
            {key: 'sensor', label:'Información del Sensor', class:'align-middle'},            
            {key: 'f_action', label:'', class:'align-middle'},
          ],
        },  
        tableTrough : {
          items: [],
          fields: [            
            {key: 'alias', label: 'Alias', class:'align-middle'},
            {key: 'sensor', label:'Sensor', class:'text-center align-middle'},            
            {key: 'status', label:'Estado', class:'text-center align-middle'},            
            {key: 'f_action', label:'', class:'align-middle'},
          ],
        },           
        crud: {
          formTank: {   
            tank: {
              id: 0,
              name: '',
              code: '',
            },                     
            id: 0,
            expiration: '',
            serial_number: '',
            type: '',
            customer_id: 0,
            tank_id: 0,
            sensor_code: 0,
            sensor_val: '',
            alert_min: 0,
            alert_min_last: 0,
            alias: '',
            batery_volt: 0,
            batery_alert: 0,            
            lat: '',
            lng: '',
            variation: 0,
            capacity: 0,
            distance_sensors: 0,         
            alias_trough: '',
            type_asignation: 'tanque'               
          },        
        },         
        arr : {          
          customers: [],
          select: { 
            sensors: [
              { value: '1', text: 'Sensor 1' },
              { value: '2', text: 'Sensor 2' },                            
            ],          
          }           
        },    
        modal: {
          formTank: {
            active: false,
            title: ''
          },
        }                                         
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },
    mounted() {
      this.cargar()      
    },
    methods: {
      cargar () {
        let loader = this.$loading.show();
        var result = serviceAPI.obtenerClientes()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arr.customers = data          
          
          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true

            if(this.table.items[this.table.rowSelected].tanks) {
              
              this.tableTanks.items = []
              this.tableTrough.items = []

              let array = this.table.items[this.table.rowSelected].tanks
              
              array.forEach(element => {                                
                if(element.info.type_asignation == 'tanque')  {
                  this.tableTanks.items.push(element)
                }

                if(element.info.type_asignation == 'bebedero')  {
                  this.tableTrough.items.push(element)
                }
              });
            }            
          }
          loader.hide()
        })
        .catch(error => {       
          loader.hide()   
          this.$awn.alert(Error.showError(error))
        });
      },
      getRowCount (items) {
        return items.length
      },      
      onRowSelected(item) {
        this.arr.customers.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        })
      },     
      openDetail(index) {
        this.table.rowSelected = index
        this.cargar()
      },    
      openEditTank(item) {        
        this.crud.formTank.tank.id = item.id  
        this.crud.formTank.tank.name = item.name        

        this.crud.formTank.id = item.info.id        
        this.crud.formTank.serial_number = item.info.serial_number
        this.crud.formTank.expiration = item.info.expiration
        this.crud.formTank.type = item.info.type
        this.crud.formTank.customer_id = item.info.m_customers_id
        this.crud.formTank.tank_id = item.info.m_sensors_tanks_id
        this.crud.formTank.sensor_code = item.info.sensor_code
        this.crud.formTank.sensor_val = item.info.sensor_val
        this.crud.formTank.alert_min = item.info.alert_min
        this.crud.formTank.alert_min_last = item.info.alert_min_last
        this.crud.formTank.alias = item.info.alias
        this.crud.formTank.lat = item.info.lat
        this.crud.formTank.lng = item.info.lng
        this.crud.formTank.variation = item.info.variation
        this.crud.formTank.capacity = item.info.capacity
        this.crud.formTank.distance_sensors = item.info.distance_sensors
        this.crud.formTank.alias_trough = item.info.alias_trough
        this.crud.formTank.type_asignation = item.info.type_asignation

        if(item.info.batery_volt) {
          this.crud.formTank.batery_volt = item.info.batery_volt
        } else {
          this.crud.formTank.batery_volt = 0
        }
        
        if(item.info.batery_alert) {
          this.crud.formTank.batery_alert = item.info.batery_alert
        } else {
          this.crud.formTank.batery_alert = 0
        }
        
        this.modal.formTank.title = "Editar Tanque Asignado"
        this.modal.formTank.active = true
      },
      saveTank() {
        let loader = this.$loading.show();        
        var result = serviceAPI.editarVinculacionTanque(this.crud.formTank);       

        result.then((response) => {
          this.modal.formTank.active = false
          loader.hide()
          this.cargar()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      openRemoveTank(item) {
        this.crud.formTank.tank.id = item.id  
        this.crud.formTank.tank.name = item.name        
        this.crud.formTank.tank.code = item.code        

        this.crud.formTank.id = item.info.id        
        this.crud.formTank.alias = item.info.alias
        this.crud.formTank.serial_number = item.info.serial_number
        this.crud.formTank.expiration = item.info.expiration
        this.crud.formTank.type = item.info.type

        var textDelete = 'Serie: ' + this.crud.formTank.serial_number + 
                         ', Vencimiento: ' + moment(this.crud.formTank.expiration).format('DD/MM/YYYY') + 
                         ', Contenido: ' + this.crud.formTank.type
        
        var refDelete = ''
        if(item.info.alias) {
          refDelete =  this.crud.formTank.alias
        }
        else {
          refDelete = this.crud.formTank.tank.code + ' - '+ this.crud.formTank.tank.name
        }        

        this.$bvModal.msgBoxConfirm('Esta acción borrará todos los datos recolectados por el sensor asignado, esta acción es irreversible. ¿Desea borrar el item ' + refDelete + '. [' +  textDelete + ']?', {
          title: 'Quitar Tanque',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: "danger",
          headerTextVariant: "white",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.unlinkTank()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },   
      unlinkTank() {
        let loader = this.$loading.show();
        var result = serviceAPI.desvincularTanque(this.crud.formTank);

        result.then((response) => {
          this.table.rowSelected = null
          loader.hide()
          this.cargar()
          this.$awn.success("Tanque desvinculado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      openAssociate() {
        this.$router.push({ name: 'SensorsStaffCrudSensorsAssociateTankCustomer' })
      },
      irViewData(item) {        
        this.$router.push({ name: 'SensorsStaffDetailSensorsTankCustomer', params: { customerTankID: item.info.id } })
      },      
      irNotifications(item) {
        this.$router.push({ name: 'SensorsStaffNotificationsSensorsTankCustomer', params: { customerTankID: item.info.id } })                  
      }      
    }  
  }
</script>
<style>
  .list-sensores-thumbnail-custom{
    text-align: center;
    margin-top: 25px;
  }
  .list-sensores-thumbnail-custom svg {
    padding: 0.25rem;
    background-color:
    #e4e5e6;
    border: 1px solid
    #c8ced3;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;    
  }
  .list-sensors-customers-trough-open {
    font-size: 18px;
    text-transform: uppercase;
    color: darkgreen;
    font-weight: bold;
  } 
  .list-sensors-customers-trough-close {
    font-size: 18px;
    text-transform: uppercase;
    color: darkred;
    font-weight: bold;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>